import React from "react"
import { Button } from "@chakra-ui/core"

export function OrangeButton({ children, ...props }) {
  return (
    <Button
      bg="#F28444"
      color="white"
      _hover={{bg:"#EE6C20"}}
      {...props}
    >
      { children }
    </Button>
  )
}
