import React from "react"
import { Box, SimpleGrid, Text, Icon } from "@chakra-ui/core"
import { Link } from "gatsby"
import { FaWhatsapp, FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa"

import CustomIcon from "./CustomIcon"

export default function Footer() {
  return (
    <Box
      bg="rgb(140,170,60)"
      className="footer-bg"
      w="100%"
      px={{base: 5, lg: 5}}
      paddingTop="30px"
      paddingBottom="10px"
      minH="150px"
      color="#fffff0"
    >
      <SimpleGrid
        columns={{base: 1, md: 2}}
      >
        <Box fontSize="xl" m={2}>
          <Box> <Link to="/"> Home </Link> </Box>
          <Box> <Link to="/about"> About </Link> </Box>
          <Box> <Link to="/contact"> Contact Us </Link> </Box>
          <Box> <Link to="/join-us"> Join Us </Link> </Box>
        </Box>

        <Box m={1}>
          <CustomIcon iconComponent={FaInstagram} text="/schoolofbirds" link="https://instagram.com/schoolofbirds" />
          <CustomIcon iconComponent={FaFacebook} text="/schoolofbirds" link="https://facebook.com/schoolofbirds" />
          <CustomIcon iconComponent={FaYoutube} text="/schoolofbirds" link="https://youtube.com/schoolofbirds" />
          <CustomIcon iconName="email" text="schoolofbirds@gmail.com" link="mailto:schoolofbirds@gmail.com" />
          <CustomIcon iconComponent={FaWhatsapp} text="+91-8335094161" link="https://wa.me/8335094161" />
          <CustomIcon iconName="phone" text="+91-7980383950" />
        </Box>
      </SimpleGrid>

      <Box>
        <Text textAlign={{base: "left", md: "center"}} fontSize="xs" mt={6}>
          &copy; School of Birds. All rights reserved.
        </Text>
      </Box>
    </Box>
  )
}
