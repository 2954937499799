import React from "react"
import { Box, Icon } from "@chakra-ui/core"
import { Link } from "gatsby"

const CustomIcon = ({ iconName, iconComponent, text, link, ...props }) => {
  const icon = iconName ? (<Icon name={iconName} />) : (<Box as={iconComponent} display="inline" />)
  return (
    <Box {...props}>
      { link ?
        (<a href={link}> {icon} {text} </a>) :
        ( <> {icon} { text } </> )
      }
    </Box>
  )
}

export default CustomIcon;
