import React from "react"
import { Box } from "@chakra-ui/core";
import { Helmet } from "react-helmet"

import Header from '../components/Header'
import Footer from '../components/Footer'

export default function Layout({ children }) {
  return (
    <Box maxWidth="1700px" margin="0 auto" backgroundColor="#fff">
     <Helmet>
        <title>School Of Birds</title>
      </Helmet>
      <Header />
      <Box p={8} minHeight="70vh" color="rgb(26,32,44)" mb={1}>
        {children}
      </Box>
      <Footer/>
    </Box>
  )
}


