import React from "react"
import { Link } from "gatsby"
import {
  Flex,
  Box,
  PseudoBox,
  Button,
  Image,
  Stack,
  /* menu items */
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/core";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

import { OrangeButton } from "../components/Button"
import Logo from "../images/sob-logo.png"


export default function Header() {
  const [show, setShow] = React.useState(false)
  const handleToggle = () => setShow(!show)

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1rem"
      color="rgb(140,170,60)"
      px={4}
      py={2}
      shadow={{base: 'sm', md: 'sm'}}
    >

      {/* the logo */ }
      <Flex align="center" mr={5}>
        <Box>
          <Link to="/">
            <Image src={Logo} alt="logo" height={80} width={80}/>
          </Link>
        </Box>
      </Flex>

      {/* the hamburger icon CTA */ }
      <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
        <svg
          fill="rgb(140,170,60)"
          width="20px"
          cursor="pointer"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          p={2}
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      {/* the main navigation links */ }
      <Box
        display={{ base: show ? "block" : "none", md: "flex"}}
        width={{ base: "full", md: "auto" }}
        flexGrow={1}
        alignItems="center"
        marginLeft={1}
      >
        <MenuDirectLink to="/about"> ABOUT </MenuDirectLink>
        {/*  <MenuDirectLink to="/projects"> PROJECTS </MenuDirectLink> */ }
        <MenuDirectLink to="/contact"> CONTACT </MenuDirectLink>
      </Box>

      {/* the social icons */ }
      <SocialIcons display={{ base: show ? "block" : "none", lg: "block" }} />

      {/* the Join Us CTA */ }
      <Box
        display={{ base: show ? "block" : "none", md: "block" }}
        width={{ base: "full", md: "auto" }}
        mt={{ base: 5, md: 0 }}
      >
        <OrangeButton leftIcon="star" size="lg" variant="solid">
          <Link to="/join-us"> Join Us </Link>
        </OrangeButton>
      </Box>
    </Flex>
  )
}


/* Dropdown menu example
 *
<DropdownMenu
  menuName="OUR WORK"
  menuItems={[
    {name: 'OUR IDEA', slug: '/projects/idea'},
    {name: 'PROJECTS', slug: '/projects/work'},
]} />
 */

const MenuDirectLink = ({ children, selected, to }) => (
  <PseudoBox
    mt={{ base: 4, md: 0 }}
    mr={6}
    display="block"
    fontWeight="bold"
    cursor="pointer"
    fontSize="18px"
  >
    <Link
      to={to}
      activeStyle={{borderBottom: '3px solid'}}
    >
      {children}
    </Link>
  </PseudoBox>
)


const DropdownMenu = ({ menuName, menuItems }) => {
  return (
    <Menu
      mt={{ base: 4, md: 0 }}
      mr={6}
      display="block"
      fontWeight={900}
    >
      <MenuButton
        as={Button}
        rightIcon="chevron-down"
        display="block"
        fontWeight="bold"
      >
        { menuName }
      </MenuButton>
      <MenuList>
        { menuItems.map(item =>
          (<MenuItem> <Link to={item.slug}> { item.name } </Link> </MenuItem>))
        }
      </MenuList>
    </Menu>
  )
}

const SocialIcons = (props) => {
  const CustomIcon = ({ icon, title, link }) => (
    <a href={link}>
      <Box as={icon} size={10} p={1} borderRadius={15} m={1} title={title} />
    </a>
  )
  return (
    <Flex
      w={{base: "full", md: "auto"}}
      flexDirection={{base: "column", md: "row"}}
      mr={{base: 0, md: 5}}
      my={{base: 4, md: 0}}
      {...props}
    >
      <Stack isInline>
        <CustomIcon link="https://instagram.com/schoolofbirds" icon={FaInstagram} title="Instagram" />
        <CustomIcon link="https://facebook.com/schoolofbirds" icon={FaFacebook} title="Facebook" />
        <CustomIcon link="https://youtube.com/schoolofbirds" icon={FaYoutube} title="Youtube"/>
      </Stack>
    </Flex>
  )
}
